import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { OneLinkWrapper } from '../pages/one-link/OneLinkWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { STRINGS } from "../../utils/Enums";
import { GetCurrentUser } from '../../utils/DefaultFunctions'
import EditForm from '../pages/BranchMain/Inventory/forms/EditForm'
import SendToCFC from '../pages/CFC/Forms/SendToCFC'
import AddCFCForm from '../pages/CFC/CFCScreen/forms/AddCFCForm'
import AddFormInventory from '../pages/BranchMain/Inventory/forms/AddFormInventory'
// import { CFCDashboard } from '../pages/CFC';
// import { BranchDefault } from '../pages/Branch';
// import { CFCDefault } from '../pages/CFC/CFCScreen';
// import { HistoryDefault } from '../pages/History';
// import { CardEmboseDefault } from '../pages/CardEmbose';
// import { SettingsDefault } from '../pages/Settings';

const PrivateRoutes = () => {
    const auth = GetCurrentUser();
    const ProcessDetailPage = lazy(() => import('../modules/processDetail/ProcessDetailPage'));
    // CFC Screens
    const CFCDashboard = lazy(() => import('../pages/CFC'));
    const BranchDefault = lazy(() => import('../pages/Branch'));
    const CFCDefault = lazy(() => import('../pages/CFC/CFCScreen'));
    const HistoryDefault = lazy(() => import('../pages/History'));
    const CardEmboseDefault = lazy(() => import('../pages/CardEmbose'));
    const SettingsDefault = lazy(() => import('../pages/Settings'));
    const AddCardCFC = lazy(() => import('../pages/CFC/CFCScreen/forms/AddCFCForm'));


    // Branch Screens
    const BranchMainDashboard = lazy(() => import('../pages/BranchMain'));
    const InventoryDefault = lazy(() => import('../pages/BranchMain/Inventory/InventoryDefault'));
    const BranchRequestForm = lazy(() => import('../pages/BranchMain/RequestForm/RequestForm'));
    const EditForm = lazy(() => import('../pages/BranchMain/Inventory/forms/EditForm'));  {/*added by jahanzaib*/}
    const ShredForm = lazy(() => import('../pages/CFC/Forms/ShredForm')); {/*added by jahanzaib*/}
    const DeliveryForm = lazy(() => import('../pages/CFC/Forms/DeliveryForm')); {/*added by jahanzaib*/}
    const SendToCFC = lazy(() => import('../pages/CFC/Forms/SendToCFC')); {/*added by jahanzaib*/}
    const SendToBranch = lazy(() => import('../pages/CFC/Forms/SendToBranch')); {/*added by jahanzaib*/}
    const Branch = lazy(() => import('../pages/BranchMain/BranchScreen/index'));  {/*added by jahanzaib*/}
    const InventoryAddCard = lazy(() => import('../pages/BranchMain/Inventory/forms/AddFormInventory')); {/*added by jahanzaib*/}
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    console.log('GetCurrentUser', auth)
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
                {/* Pages */}
                {/* <Route path='dashboard' element={<DashboardWrapper/>}/> */}
                <Route path={`${STRINGS.ROUTES.DASHBOARD}`} element={<SuspensedView><CFCDashboard /></SuspensedView>} />
                {
                    auth.type === 'cfc' && (
                        <>
                            <Route path={`${STRINGS.ROUTES.BRANCH_SCREEN}`} element={<SuspensedView><BranchDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.CFC_SCREEN}`} element={<SuspensedView><CFCDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.HISTORY_SCREEN}`} element={<SuspensedView><HistoryDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.CARD_EMBOSE}`} element={<SuspensedView><CardEmboseDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.EDITFORM}`} element = {<SuspensedView><EditForm /></SuspensedView>} />  {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}`} element = {<SuspensedView><ShredForm /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}`} element = {<SuspensedView><DeliveryForm /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}`} element = {<SuspensedView><SendToCFC /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}`} element = {<SuspensedView><SendToBranch /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.CFC.ADDCARDCFC}`} element = {<SuspensedView><AddCFCForm /></SuspensedView>}/> {/*added by jahanzaib*/}

                        </>
                    )
                }

                {
                    auth.type === 'branch' && (
                        <>
                            <Route path={`${STRINGS.ROUTES.DASHBOARD}`} element={<SuspensedView><BranchMainDashboard /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.INVENTORY}`} element={<SuspensedView><InventoryDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.EDITFORM}`} element = {<SuspensedView><EditForm /></SuspensedView>} />  {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.INVENTORYADDCARD}`} element = {<SuspensedView><AddFormInventory /></SuspensedView>} />  {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}`} element = {<SuspensedView><ShredForm /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}`} element = {<SuspensedView><DeliveryForm /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}`} element = {<SuspensedView><SendToCFC /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}`} element = {<SuspensedView><SendToBranch /></SuspensedView>}/> {/*added by jahanzaib*/}
                            <Route path={`${STRINGS.ROUTES.BRANCH.MAIN}`} element={<SuspensedView><CFCDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.CARDDETAILS}`} element={<SuspensedView><Branch/></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`} element={<SuspensedView><BranchRequestForm /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.HISTORY}`} element={<SuspensedView><HistoryDefault /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`} element={<SuspensedView><CardEmboseDefault /></SuspensedView>} />
                        </>
                    )
                }

                {
                    ['sambaphone', 'amd'].includes(auth.type) && (
                        <>
                            <Route path={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`} element={<SuspensedView><BranchRequestForm /></SuspensedView>} />
                            <Route path={`${STRINGS.ROUTES.BRANCH.MAIN}`} element={<SuspensedView><CardEmboseDefault /></SuspensedView>} />
                        </>
                    )
                }

                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.CARD_DES_MEMO}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK_CFC}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.CREATE_DESIGNATION}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.USER_MANAGER}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.GROUP_EMAIL}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />
                <Route path={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`} element={<SuspensedView><SettingsDefault /></SuspensedView>} />



                <Route path='oneLink/IBFT' element={<OneLinkWrapper />} />
                <Route path='builder' element={<BuilderPageWrapper />} />
                <Route path='menu-test' element={<MenuTestPage />} />
                {/* Lazy Modules */}
                <Route
                    path={`${STRINGS.ROUTES.ONE_LINK_PROCESS_DETAIL}/*`}
                    element={
                        <SuspensedView>
                            <ProcessDetailPage />
                        </SuspensedView>
                    }
                />
                {/*<Route*/}
                {/*    path='crafted/pages/profile/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ProfilePage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/pages/wizards/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WizardsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/widgets/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WidgetsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/account/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <AccountPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='apps/chat/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ChatPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='apps/user-management/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <UsersPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to={`${STRINGS.ROUTES.DASHBOARD}`} />} />
            </Route>

        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
