const DOMAIN_PREFIX = "";

export const STRINGS = {
    IBFT_ID: "ZNJBaQBMy",
    AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v',
    API_STATUS: {
        DEFAULT: 200,
        CREATE: 201,
        BAD_REQUEST: 400,
        FAIL: 1001,
        SUCCESS: 1000,
    },
    ROUTES: {
        ROOT: `${DOMAIN_PREFIX}/`,
        AUTH: `${DOMAIN_PREFIX}/auth`,
        DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
        BRANCH_SCREEN: `${DOMAIN_PREFIX}/br`,
        CFC_SCREEN: `${DOMAIN_PREFIX}/cfc`,
        HISTORY_SCREEN: `${DOMAIN_PREFIX}/his`,
        CARD_EMBOSE: `${DOMAIN_PREFIX}/crdem`,
        ONE_LINK_PROCESS_DETAIL: `${DOMAIN_PREFIX}/crdem`,
        BRANCH: {
            INVENTORY: `${DOMAIN_PREFIX}/br_inv`,
            CARDDETAILS: `${DOMAIN_PREFIX}/br_details`,
            INVENTORYADDCARD: `${DOMAIN_PREFIX}/br_inv/addcard`,
            EDITFORM: `${DOMAIN_PREFIX}/br_inv/edit`,
            SHREDFORM: `${DOMAIN_PREFIX}/shred`,
            DELIVERYFORM: `${DOMAIN_PREFIX}/delivery`,
            SENDTOCFCFORM: `${DOMAIN_PREFIX}/send_to_cfc`,
            SENDTOBRANCHFORM: `${DOMAIN_PREFIX}/send_to_branch`,
            MAIN: `${DOMAIN_PREFIX}/br_main`,
            HISTORY: `${DOMAIN_PREFIX}/br_his`,
            REQUEST_FORM: `${DOMAIN_PREFIX}/req_form`,
            MAIN_CARDS: `${DOMAIN_PREFIX}/card_main`,
        },
        CFC: {
            ADDCARDCFC: `${DOMAIN_PREFIX}/cfc/addcard`,
        },
        SETTINGS: {
            DEFAULT: `${DOMAIN_PREFIX}/settings`,
            CONFIG: {
                DEFAULT: `${DOMAIN_PREFIX}/settings/config`,
                MEMO: {
                    DEFAULT: `${DOMAIN_PREFIX}/settings/config/memo`,
                    CARD_DES_MEMO: `${DOMAIN_PREFIX}/settings/config/memo/card_destruction_memo`,
                    OTHER_BANK: `${DOMAIN_PREFIX}/settings/config/memo/samba_card_on_other_bank`,
                    OTHER_BANK_CFC: `${DOMAIN_PREFIX}/settings/config/memo/other_bank_cfc_on_samba`,
                },
                MASTER: {
                    DEFAULT: `${DOMAIN_PREFIX}/settings/config/master`,
                    CREATE_DESIGNATION: `${DOMAIN_PREFIX}/settings/config/master/create_designation`,
                    USER_MANAGER: `${DOMAIN_PREFIX}/settings/config/master/user_manager`,
                    GROUP_EMAIL: `${DOMAIN_PREFIX}/settings/config/master/group_email`,
                },
                ALERT_AND_ESCALATIONS: {
                    DEFAULT: `${DOMAIN_PREFIX}/settings/config/alert_escalations`,
                    CREATE_ALERT_DESINATION: `${DOMAIN_PREFIX}/settings/config/alert_escalations/create_alert_designation`,
                    LIST: `${DOMAIN_PREFIX}/settings/config/create_alert_designation/list`,
                }
            },

        },
    }
}