import {Suspense} from 'react'
import {Outlet, Route} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth';
import storeToolkit from './Store/storeToolkit';
import {Provider} from "react-redux";


const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Provider store={storeToolkit}>
                <I18nProvider>
                    <LayoutProvider>
                        <AuthInit>
                            <Outlet/>
                            <MasterInit/>
                        </AuthInit>
                    </LayoutProvider>
                </I18nProvider>
            </Provider>
        </Suspense>

    )
}

export {App}
