/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { STRINGS } from "../../../../utils/Enums";
import { GetCurrentUser } from '../../../../utils/DefaultFunctions'

export function AsideMenuMain() {
    const intl = useIntl();
    const auth = GetCurrentUser();

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen025.svg'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
            />
            {/*<AsideMenuItem*/}
            {/*  to='/builder'*/}
            {/*  icon='/media/icons/duotune/general/gen019.svg'*/}
            {/*  title='Layout Builder'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Process</span>
                </div>
            </div>

            {
                auth.type === 'cfc' && (
                    <>
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH_SCREEN}`} title='Branch' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.CFC_SCREEN}`} title='CFC' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.HISTORY_SCREEN}`} title='History' hasBullet={true} />
                    </>
                )
            }

            {
                auth.type === 'branch' && (
                    <>
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.INVENTORY}`} title='Inventory' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.MAIN}`} title='Main Screen' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.HISTORY}`} title='History' hasBullet={true} />
                    </>
                )
            }

            {
                ['sambaphone', 'amd'].includes(auth.type) && (
                    <>
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`} title='Request Form' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.MAIN}`} title='Main' hasBullet={true} />
                    </>
                )
            }



            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CARDS</span>
                </div>
            </div>

            {
                auth.type === 'cfc' && (
                    <AsideMenuItem to={`${STRINGS.ROUTES.CARD_EMBOSE}`} title='Card Embose' hasBullet={true} />
                )
            }

            {
                ['branch', 'cfc'].includes(auth.type) && (
                    <>
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`} title='Request Form' hasBullet={true} />
                        <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`} title='Main Screen' hasBullet={true} />
                    </>
                )
            }


            {
                auth.type === 'branch' && (
                    <>
                        <div className='menu-item'>
                            <div className='menu-content pt-8 pb-2'>
                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
                            </div>
                        </div>
                        <AsideMenuItemWithSub
                            to={`${STRINGS.ROUTES.SETTINGS.CONFIG}`}
                            title='Configure'
                            fontIcon='bi-chat-left'
                            icon='/media/icons/duotune/communication/com012.svg'
                        >
                            <AsideMenuItemWithSub
                                to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO}`}
                                title='Memo'
                                fontIcon='bi-chat-left'
                                icon='/media/icons/duotune/communication/com012.svg'
                            >
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.CARD_DES_MEMO}`} title='Cards destruction memo' hasBullet={true} />
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK}`} title='Samba card on other bank' hasBullet={true} />
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK_CFC}`} title='Other bank CFC on Samba' hasBullet={true} />
                            </AsideMenuItemWithSub>
                            <AsideMenuItemWithSub
                                to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER}`}
                                title='Master'
                                fontIcon='bi-chat-left'
                                icon='/media/icons/duotune/communication/com012.svg'
                            >
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.CREATE_DESIGNATION}`} title='Create designation' hasBullet={true} />
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.USER_MANAGER}`} title='User Manager' hasBullet={true} />
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.GROUP_EMAIL}`} title='Group Email' hasBullet={true} />
                            </AsideMenuItemWithSub>

                            <AsideMenuItemWithSub
                                to={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS}`}
                                title='Alert & Escalations'
                                fontIcon='bi-chat-left'
                                icon='/media/icons/duotune/communication/com012.svg'
                            >
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`} title='Create alert & designation' hasBullet={true} />
                                <AsideMenuItem to={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.LIST}`} title='List' hasBullet={true} />
                            </AsideMenuItemWithSub>

                        </AsideMenuItemWithSub>
                    </>
                )
            }

            {/*<AsideMenuItem*/}
            {/*  to='/apps/user-management/users'*/}
            {/*  icon='/media/icons/duotune/general/gen051.svg'*/}
            {/*  title='User management'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content'>*/}
            {/*    <div className='separator mx-1 my-4'></div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='menu-item'>*/}
            {/*  <a*/}
            {/*    target='_blank'*/}
            {/*    className='menu-link'*/}
            {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
            {/*  >*/}
            {/*    <span className='menu-icon'>*/}
            {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
            {/*    </span>*/}
            {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
            {/*  </a>*/}
            {/*</div>*/}
        </>
    )
}
