import React, {useState} from 'react';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

const DatePicker = ({defaultDate,className,placeholder}) => {
    const [init, setInit] = useState({
        date: new Date()
    });

    return (
        <Flatpickr
            data-enable-time
            className={className}
            placeholder={placeholder}
            value={init.date}
            onChange={([date]) => {
                setInit({
                    ...init,
                    date: date
                });
            }}
        />
    );
};

export default DatePicker;