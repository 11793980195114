import axios from "axios";

export const BASE_DOMAIN = (() => {
    if (window.location.hostname === "umeed.io" || window.location.hostname === "www.umeed.io" || window.location.hostname === "kistpay-portal.kistpay.com")
        return "https://retailer.kistpay.com";
    else
        return "http://159.69.72.39:5001";
})();

export const BASE_URL = BASE_DOMAIN;
export const API = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    httpAgent: true
});
