import React from 'react'
// import { Link } from 'react-router-dom'

const EditForm = () => {
    return (
        <div class="row g-6 g-xl-9 tab-content" id="myTabContent">
            {/* <!--Mytabcontent start tab1--> */}
            <div class="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
                {/* <!--begin::Col--> */}
                <div class="card mb-5 mb-xl-10">
                    {/* <!--begin::Card header--> */}
                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                        {/* <!--begin::Card title--> */}
                        <div class="card-title m-0">
                            <h3 class="fw-bold m-0">Edit Form</h3>
                        </div>
                        {/* <!--end::Card title--> */}
                    </div>
                    {/* <!--begin::Card header--> */}
                    {/* <!--begin::Content--> */}
                    <div id="kt_account_settings_profile_details" class="collapse show">
                        {/* <!--begin::Form--> */}
                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                            {/* <!--begin::Card body--> */}
                            <div class="card-body border-top p-9">
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Capture Date & Capture Time</label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8">
                                        {/* <!--begin::Row--> */}
                                        <div class="row">
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input class="form-control form-control-solid" placeholder="Capture date (Will be auto populated)" />
                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input class="form-control form-control-solid flatpickr-input" placeholder="Capture Time (Will be auto populated)" type="text" readonly="readonly" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                        </div>
                                        {/* <!--end::Row--> */}
                                    </div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Customer Name</label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer Name (Will be auto populated)" value="" />
                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Card Number</label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Number (Will be auto populated)" value="" />
                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Title of Account</label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Title of Account (Will be auto populated)" value="" />
                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Account Number</label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Account Number (Will be auto populated)" value="" />
                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Issuer Branch & Bank </label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8">
                                        {/* <!--begin::Row--> */}
                                        <div class="row">
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name (Will be auto populated)" value="" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name (Will be auto populated)" value="" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                        </div>
                                        {/* <!--end::Row--> */}
                                    </div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                                {/* <!--begin::Input group--> */}
                                <div class="row mb-6">
                                    {/* <!--begin::Label--> */}
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Acquirer Branch & Bank </label>
                                    {/* <!--end::Label--> */}
                                    {/* <!--begin::Col--> */}
                                    <div class="col-lg-8">
                                        {/* <!--begin::Row--> */}
                                        <div class="row">
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name (Will be auto populated)" value="" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name (Will be auto populated)" value="" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {/* <!--end::Col--> */}
                                        </div>
                                        {/* <!--end::Row--> */}
                                    </div>
                                    {/* <!--end::Col--> */}
                                </div>
                                {/* <!--end::Input group--> */}
                            </div>
                            {/* <!--end::Card body--> */}
                            {/* <!--begin::Actions--> */}
                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                <a type="" class="btn btn-light btn-active-light-primary me-2">Print</a>
                                <a type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Save</a>
                            </div>
                            {/* <!--end::Actions--> */}
                            <input type="hidden" /><div></div></form>
                        {/* <!--end::Form--> */}
                    </div>
                    {/* <!--end::Content--> */}
                </div>
            </div>
            {/* <!--endtab1--> */}
        </div>
    )
}

export default EditForm
