import React, { useState } from 'react';

const AddFormInventory = () => {

    const [init, setInit] = useState({
        tabs: ['Same Bank', 'Other Bank', 'Others'],
        selectedTab: 0,
    });

    const { tabs, selectedTab } = init;

    return (
        <div id="kt_app_content" class="app-content flex-column-fluid">
            {/*<!--begin::Content container-->*/}
            <div id="kt_app_content_container" class="app-container container-xx">
                {/*<!--begin::Navbar-->*/}
                <div class="card mb-6 mb-xl-9">
                    <div class="card-body pt-9 pb-0">
                        {/*<!--begin::Details-->*/}
                        <div class="d-flex flex-wrap flex-sm-nowrap mb-6">
                            {/*<!--begin::Nav-->*/}
                            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                {/*<!--begin::Nav item-->*/}
                                {
                                    tabs?.map((tb, index) => {
                                        return (
                                            <li class="nav-item" onClick={() => {
                                                setInit({
                                                    ...init,
                                                    selectedTab: index
                                                })
                                            }}>
                                                <div class={`nav-link text-active-primary py-5 me-6 ${selectedTab === index && "active"}`} data-bs-toggle="tab" href="#kt_tab_pane_1" aria-selected="false" role="tab" tabindex="-1">{tb}</div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {/*<!--end::Nav-->*/}
                        </div>
                        {/*<!--end::Details-->*/}


                    </div>
                </div>
                {/*<!--end::Navbar-->*/}
                {/*<!--begin::Row-->*/}
                {/*<!--Mytabcontent start-->*/}
                <div class="row g-6 g-xl-9 tab-content" id="myTabContent">
                    {/*<!--Mytabcontent start tab1-->*/}
                    {
                        selectedTab === 0 && (
                            <div class={`tab-pane fade active show`} id="kt_tab_pane_1" role="tabpanel">
                                {/*<!--begin::Col-->*/}
                                <div class="card mb-5 mb-xl-10">
                                    {/*<!--begin::Card header-->*/}
                                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        {/*<!--begin::Card title-->*/}
                                        <div class="card-title m-0">
                                            <h3 class="fw-bold m-0">Add Card (Same Bank)</h3>
                                        </div>
                                        <div class="card-toolbar">
                                            {/*<!--begin::Menu-->*/}
                                            <div class="col-lg-">
                                                {/*<!--begin::Search-->*/}
                                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                    {/*<!--begin::Secondary button-->*/}
                                                    {/*<!--end::Secondary button-->*/}
                                                    {/*<!--begin::Primary button-->*/}
                                                    <div class="d-flex align-items-center position-relative my-1">
                                                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                                                        <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {/*<!--end::Svg Icon-->*/}
                                                        <input type="text" id="kt_filter_search kt_docs_maxlength_threshold" class="form-control form-control-solid form-select-sm mb-3 mb-lg-0 ps-9" placeholder="Enter Card number" maxlength="16" />
                                                    </div>
                                                    <div>
                                                        <a class="btntextwhite btn btn-sm btn-bg-success" id="kt_user_follo_button" id="cardtextwhite">
                                                            {/*<!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->*/}
                                                            <span class="svg-icon svg-icon-3 d-none">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                </svg>
                                                            </span>
                                                            {/*<!--end::Svg Icon-->*/}
                                                            {/*<!--begin::Indicator label-->*/}
                                                            <span class="indicator-label">Search</span>
                                                            {/*<!--end::Indicator label-->*/}
                                                            {/*<!--begin::Indicator progress-->*/}
                                                            {/* <span class="indicator-progress">Please wait...
                                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> */}
                                                            {/*<!--end::Indicator progress-->*/}
                                                        </a>
                                                    </div>
                                                    {/*<!--end::Primary button-->*/}
                                                </div>
                                                {/*<!--end::Search -->*/}
                                            </div>
                                            {/*<!--begin::Menu 2-->*/}

                                            {/*<!--end::Menu 2-->*/}
                                            {/*<!--end::Menu-->*/}
                                        </div>
                                        {/*<!--end::Card title-->*/}
                                    </div>
                                    {/*<!--begin::Card header-->*/}
                                    {/*<!--begin::Content-->*/}
                                    <div id="kt_account_settings_profile_details" class="collapse show">
                                        {/*<!--begin::Form-->*/}
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                            {/*<!--begin::Card body-->*/}
                                            <div class="card-body border-top p-9">
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Capture Date / Time</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid" placeholder="Select date" id="kt_datepicker_2" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid flatpickr-input" placeholder="Select Time" id="kt_datepicker_8" type="text" readonly="readonly" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer Branch & Bank </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer City</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">CIF Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Enter CIF Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Expiry</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Expiry" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Customer Name</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer Name" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Type</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">Visa</option>
                                                            <option value="2">MasterCard</option>
                                                            <option value="3">PayPak</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Status</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">Hot</option>
                                                            <option value="2">Active</option>
                                                            <option value="3">Warm</option>
                                                            <option value="4">Suspicious</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Reason for Captured</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Reason" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Issuer Branch & Bank </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Issuer City</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Note</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <textarea name="kt_docs_ckeditor_classic" id="kt_docs_ckeditor_classic">

                                                        </textarea>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card body-->*/}
                                            {/*<!--begin::Actions-->*/}
                                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                                {/*<!--<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button> -->*/}
                                                <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Submit</button>
                                            </div>
                                            {/*<!--end::Actions-->*/}
                                            <input type="hidden" /><div></div></form>
                                        {/*<!--end::Form-->*/}
                                    </div>
                                    {/*<!--end::Content-->*/}
                                </div>
                            </div>
                        )
                    }
                    {/*<!--endtab1-->*/}
                    {/*<!--begintab2-->*/}
                    {
                        selectedTab === 1 && (
                            <div class="tab-pane fade active show" id="kt_tab_pane_2" role="tabpanel">
                                <div class="card mb-5 mb-xl-10">
                                    {/*<!--begin::Card header-->*/}
                                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        {/*<!--begin::Card title-->*/}
                                        <div class="card-title m-0">
                                            <h3 class="fw-bold m-0">Add Card (Other Bank)</h3>
                                        </div>
                                        <div class="card-toolbar">
                                            {/*<!--begin::Menu-->*/}
                                            <div class="col-lg-">
                                                {/*<!--begin::Search-->*/}
                                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                    {/*<!--begin::Secondary button-->*/}
                                                    {/*<!--end::Secondary button-->*/}
                                                    {/*<!--begin::Primary button-->*/}
                                                    <div class="d-flex align-items-center position-relative my-1">
                                                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                                                        <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {/*<!--end::Svg Icon-->*/}
                                                        <input type="text" id="kt_filter_search kt_docs_maxlength_threshold" class="form-control form-control-solid form-select-sm mb-3 mb-lg-0 ps-9" placeholder="Enter Card number" maxlength="16" />
                                                    </div>
                                                    <div>
                                                        <a class="btntextwhite btn btn-sm btn-bg-success" id="kt_user_follo_button" id="cardtextwhite">
                                                            {/*<!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->*/}
                                                            <span class="svg-icon svg-icon-3 d-none">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                </svg>
                                                            </span>
                                                            {/*<!--end::Svg Icon-->*/}
                                                            {/*<!--begin::Indicator label-->*/}
                                                            <span class="indicator-label">Search</span>
                                                            {/*<!--end::Indicator label-->*/}
                                                            {/*<!--begin::Indicator progress-->*/}
                                                            {/* <span class="indicator-progress">Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> */}
                                                            {/*<!--end::Indicator progress-->*/}
                                                        </a>
                                                    </div>
                                                    {/*<!--end::Primary button-->*/}
                                                </div>
                                                {/*<!--end::Search -->*/}
                                            </div>
                                            {/*<!--begin::Menu 2-->*/}

                                            {/*<!--end::Menu 2-->*/}
                                            {/*<!--end::Menu-->*/}
                                        </div>
                                        {/*<!--end::Card title-->*/}
                                    </div>
                                    {/*<!--begin::Card header-->*/}
                                    {/*<!--begin::Content-->*/}
                                    <div id="kt_account_settings_profile_details" class="collapse show">
                                        {/*<!--begin::Form-->*/}
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                            {/*<!--begin::Card body-->*/}
                                            <div class="card-body border-top p-9">
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Capture Date / Time</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid" placeholder="Select date" id="kt_datepicker_4" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid flatpickr-input" placeholder="Select Time" id="kt_datepicker_11" type="text" readonly="readonly" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer Branch & Bank </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer City</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">CIF Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Enter CIF Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Expiry</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Expiry" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Customer Name</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer Name" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Card Owner</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select a Card Owner" data-allow-clear="true">
                                                            <option></option>
                                                            <option value="1">Benezir Income Support</option>
                                                            <option value="2">SadaPay</option>
                                                            <option value="3">QuickPay</option>
                                                            <option value="4">Habib Bank Ltd</option>
                                                            <option value="5">JS Bank</option>
                                                            <option value="6">Standard Charted Bank</option>
                                                            <option value="7">UBL Bank Ltd</option>
                                                            <option value="8">Meezan Bank</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Type</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">Visa</option>
                                                            <option value="2">MasterCard</option>
                                                            <option value="3">PayPak</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Status</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">Hot</option>
                                                            <option value="2">Active</option>
                                                            <option value="3">Warm</option>
                                                            <option value="4">Suspicious</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Reason for Captured</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Reason" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Issuer Bank</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Issuer Bank" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Note</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <textarea name="kt_docs_ckeditor_classic2" id="kt_docs_ckeditor_classic2">

                                                        </textarea>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card body-->*/}
                                            {/*<!--begin::Actions-->*/}
                                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                                {/*<!--<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button> -->*/}
                                                <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Submit</button>
                                            </div>
                                            {/*<!--end::Actions-->*/}
                                            <input type="hidden" /><div></div></form>
                                        {/*<!--end::Form-->*/}
                                    </div>
                                    {/*<!--end::Content-->*/}
                                </div>
                            </div>
                        )
                    }
                    {/*<!--endtab2-->*/}
                    {/*<!--begintab3-->*/}
                    {
                        selectedTab === 2 && (
                            <div class="tab-pane fade active show" id="kt_tab_pane_3" role="tabpanel">
                                <div class="card mb-5 mb-xl-10">
                                    {/*<!--begin::Card header-->*/}
                                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        {/*<!--begin::Card title-->*/}
                                        <div class="card-title m-0">
                                            <h3 class="fw-bold m-0">Add Card (Whitelabel)</h3>
                                        </div>
                                        <div class="card-toolbar">
                                            {/*<!--begin::Menu-->*/}
                                            <div class="col-lg-">
                                                {/*<!--begin::Search-->*/}
                                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                    {/*<!--begin::Secondary button-->*/}
                                                    {/*<!--end::Secondary button-->*/}
                                                    {/*<!--begin::Primary button-->*/}
                                                    <div class="d-flex align-items-center position-relative my-1">
                                                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                                                        <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {/*<!--end::Svg Icon-->*/}
                                                        <input type="text" id="kt_filter_search kt_docs_maxlength_threshold" class="form-control form-control-solid form-select-sm mb-3 mb-lg-0 ps-9" placeholder="Enter Card number" maxlength="16" />
                                                    </div>
                                                    <div>
                                                        <a class="btntextwhite btn btn-sm btn-bg-success" id="kt_user_follo_button">
                                                            {/*<!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->*/}
                                                            <span class="svg-icon svg-icon-3 d-none">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                                                </svg>
                                                            </span>
                                                            {/*<!--end::Svg Icon-->*/}
                                                            {/*<!--begin::Indicator label-->*/}
                                                            <span class="indicator-label">Search</span>
                                                            {/*<!--end::Indicator label-->*/}
                                                            {/*<!--begin::Indicator progress-->*/}
                                                            {/* <span class="indicator-progress">Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> */}
                                                            {/*<!--end::Indicator progress-->*/}
                                                        </a>
                                                    </div>
                                                    {/*<!--end::Primary button-->*/}
                                                </div>
                                                {/*<!--end::Search -->*/}
                                            </div>
                                            {/*<!--begin::Menu 2-->*/}

                                            {/*<!--end::Menu 2-->*/}
                                            {/*<!--end::Menu-->*/}
                                        </div>
                                        {/*<!--end::Card title-->*/}
                                    </div>
                                    {/*<!--begin::Card header-->*/}
                                    {/*<!--begin::Content-->*/}
                                    <div id="kt_account_settings_profile_details" class="collapse show">
                                        {/*<!--begin::Form-->*/}
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                            {/*<!--begin::Card body-->*/}
                                            <div class="card-body border-top p-9">
                                                {/*<!--begin::Input group-->*/}

                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Capture Date / Time</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid" placeholder="Select date" id="kt_datepicker_5" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input class="form-control form-control-solid flatpickr-input" placeholder="Select Time" id="kt_datepicker_12" type="text" readonly="readonly" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer Branch & Bank </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer City</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Customer Name</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer Name" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Card Type</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">International</option>
                                                            <option value="2">Plain</option>
                                                            <option value="3">Suspected</option>
                                                            <option value="4">Off-Us</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">CIF Number</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Enter CIF Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}

                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Action</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <select class="form-select form-select-solid" data-control="select2" data-placeholder="Select Card Type" data-hide-search="true">
                                                            <option></option>
                                                            <option value="1">Hot</option>
                                                            <option value="2">Active</option>
                                                            <option value="3">Warm</option>
                                                            <option value="4">Suspicious</option>
                                                        </select>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Issuer Branch & Bank </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8">
                                                        {/*<!--begin::Row-->*/}
                                                        <div class="row">
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                            {/*<!--begin::Col-->*/}
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name" value="" />
                                                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {/*<!--end::Col-->*/}
                                                        </div>
                                                        {/*<!--end::Row-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Issuer City</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row mb-6">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">Note</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <textarea name="kt_docs_ckeditor_classic3" id="kt_docs_ckeditor_classic3">

                                                        </textarea>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card body-->*/}
                                            {/*<!--begin::Actions-->*/}
                                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                                {/*<!--<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button> -->*/}
                                                <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Submit</button>
                                            </div>
                                            {/*<!--end::Actions-->*/}
                                            <input type="hidden" /><div></div></form>
                                        {/*<!--end::Form-->*/}
                                    </div>
                                    {/*<!--end::Content-->*/}
                                </div>
                            </div>
                        )
                    }
                    {/*<!--end tab3-->*/}
                </div>
                {/*<!--Mytabcontent end-->*/}
                {/*<!--end::Row-->*/}
                {/*<!--begin::Table-->*/}

            </div>
            {/*<!--end::Content container-->*/}
        </div>
    );
};

export default AddFormInventory;