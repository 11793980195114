import React, { useState } from 'react';

const AddCFCForm = () => {
    const [init, setInit] = useState({
        selectedRec: 2,
        isSearchPending: false
    });

    let { selectedRec, isSearchPending } = init;

    const handleSwitchRecivedThrow = (val) => {
        setInit({
            ...init,
            selectedRec: val
        })
    }

    return (
        <div class="card mb-5 mb-xl-10">
            {/*<!--begin::Card header-->*/}
            <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                {/*<!--begin::Card title-->*/}
                <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Add Card (CFC)</h3>
                </div>
                <div class="card-toolbar">
                    {/*<!--begin::Menu-->*/}
                    <div class="col-lg-">
                        {/*<!--begin::Search-->*/}
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            {/*<!--begin::Secondary button-->*/}
                            {/*<!--end::Secondary button-->*/}
                            {/*<!--begin::Primary button-->*/}
                            <div class="d-flex align-items-center position-relative my-1">
                                {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                                <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                {/*<!--end::Svg Icon-->*/}
                                <input type="text" id="kt_filter_search kt_docs_maxlength_threshold" class="form-control form-control-solid form-select-sm mb-3 mb-lg-0 ps-9" placeholder="Enter Card number" maxlength="16" />
                            </div>
                            <div>
                                <a class="btn btn-sm btn-bg-success btntextwhite" id="kt_user_follo_button">
                                    {/*<!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->*/}
                                    <span class="svg-icon svg-icon-3 d-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    {/*<!--end::Svg Icon-->*/}
                                    {/*<!--begin::Indicator label-->*/}
                                    <span class="indicator-label">Search</span>
                                    {/*<!--end::Indicator label-->*/}
                                    {/*<!--begin::Indicator progress-->*/}
                                    {
                                        isSearchPending && (
                                            <span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        )
                                    }
                                    {/*<!--end::Indicator progress-->*/}
                                </a>
                            </div>
                            {/*<!--end::Primary button-->*/}
                        </div>
                        {/*<!--end::Search -->*/}
                    </div>
                    {/*<!--begin::Menu 2-->*/}

                    {/*<!--end::Menu 2-->*/}
                    {/*<!--end::Menu-->*/}
                </div>
                {/*<!--end::Card title-->*/}
            </div>
            {/*<!--begin::Card header-->*/}
            {/*<!--begin::Content-->*/}
            <div id="kt_account_settings_profile_details" class="collapse show">
                {/*<!--begin::Form-->*/}
                <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                    {/*<!--begin::Card body-->*/}
                    <div class="card-body border-top p-9">
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Capture Date / Time</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8">
                                {/*<!--begin::Row-->*/}
                                <div class="row">
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input class="form-control form-control-solid" placeholder="Select date" id="kt_datepicker_2" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input class="form-control form-control-solid flatpickr-input" placeholder="Select Time" id="kt_datepicker_8" type="text" readonly="readonly" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                </div>
                                {/*<!--end::Row-->*/}
                            </div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label required fw-semibold fs-6">Receiving Date / Time</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8">
                                {/*<!--begin::Row-->*/}
                                <div class="row">
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input class="form-control form-control-solid" placeholder="Select date" id="kt_datepicker_3" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input class="form-control form-control-solid flatpickr-input" placeholder="Select Time" id="kt_datepicker_9" type="text" readonly="readonly" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                </div>
                                {/*<!--end::Row-->*/}
                            </div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Expiry Date</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Expiry Date (Will be auto populated)" value="" readonly />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label required fw-semibold fs-6">Acquirer Bank</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="name_input" id="name" class="form-control form-control-lg form-control-solid" placeholder="Acquiring Bank" value="" />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label  fw-semibold fs-6">Acquirer Branch & City </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8">
                                {/*<!--begin::Row-->*/}
                                <div class="row">
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch Name" value="" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="City" value="" />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                </div>
                                {/*<!--end::Row-->*/}
                            </div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Card Number</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Number (Will be auto populted)" value="" readonly />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Customer CIF</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer CIF (Will be auto populted)" value="" readonly />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Customer Name</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Customer Name (Will be auto populted)" value="" readonly />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Card Type</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Type (Will be auto populted)" value="" readonly />
                                <div />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">CNIC</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="CNIC (Will be auto populted)" value="" readonly />
                                <div />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Card Status</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Card Status (Will be auto populted)" value="" readonly />
                                <div />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label  fw-semibold fs-6">Issuer Bank & Branch</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8">
                                {/*<!--begin::Row-->*/}
                                <div class="row">
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch (Will be auto populted)" value="" readonly />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                    {/*<!--begin::Col-->*/}
                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Bank Name (Will be auto populted)" value="" readonly />
                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*<!--end::Col-->*/}
                                </div>
                                {/*<!--end::Row-->*/}
                            </div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Branch City</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Branch City (Will be auto populted)" value="" readonly />
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}
                        {/*<!--begin::Input group-->*/}
                        <div class="row mb-6">
                            {/*<!--begin::Label-->*/}
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">Received through</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Col-->*/}
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <div class="radio-inline">
                                    {/*<!--begin::Radio-->*/}
                                    {/*<!--begin::Radio group-->*/}
                                    <div class="btn-group w-100 w-lg-50" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                                        {/*<!--begin::Radio-->*/}
                                        <label class={`btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${selectedRec === 1 && "active"}`} data-kt-button="true">
                                            {/*<!--begin::Input-->*/}
                                            <input class="btn-check" type="radio" onClick={() => {
                                                handleSwitchRecivedThrow(1)
                                            }} checked="checked" name="yesno" id="yesCheck" value="1" />
                                            {/*<!--end::Input-->*/}
                                            Courier
                                        </label>
                                        {/*<!--end::Radio-->*/}

                                        {/*<!--begin::Radio-->*/}
                                        <label class={`btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${selectedRec === 2 && "active"}`} data-kt-button="true">
                                            {/*<!--begin::Input-->*/}
                                            <input class="btn-check leftmargin1" type="radio" onClick={() => {
                                                handleSwitchRecivedThrow(2)
                                            }} name="yesno" id="noCheck" checked="checked" value="2" />																				{/*<!--end::Input-->*/}
                                            Rider
                                        </label>
                                        {/*<!--end::Radio-->*/}
                                    </div>
                                    {/*<!--end::Radio group-->*/}

                                    {/*<!--end::Radio-->*/}

                                    <br /><br /><br />
                                    {
                                        selectedRec === 1 && (
                                            <div id="ifYes" class="hidediv active">
                                                <div class="row">
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <label class="col-lg-6 col-form-label fw-semibold fs-6">Courier Company</label>
                                                        <div class="" data-kt-modal-bidding-type="dollar">
                                                            {/*<!--begin::Select2-->*/}
                                                            <select name="status" aria-label="Select a Currency" data-placeholder="Select a currency.." class="form-select form-select-solid form-select-lg " data-select2-id="select2-data-121-2l6u" tabindex="-1" aria-hidden="true">
                                                                <option value="1" selected="selected">Select</option>
                                                                <option value="2">TCS</option>
                                                                <option value="3">Leopard</option>
                                                                <option value="3">DHL</option>
                                                            </select>

                                                            {/*<!--end::Select2-->*/}
                                                        </div>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <label class="col-lg-6 col-form-label required fw-semibold fs-6">Courier Number</label>
                                                        <input type="text" id="fname" name="win" class="form-control form-control-lg form-control-solid" placeholder="Enter Courier Number" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <label class="col-lg-6 col-form-label required fw-semibold fs-6">Dispatch Time</label>
                                                        <input class="form-control form-control-solid flatpickr-input active" placeholder="Select Time" id="kt_datepicker_10" type="text" readonly="readonly" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}

                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        selectedRec === 2 && (
                                            <div id="ifNo" class="hidediv active">
                                                <div class="row">
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <label class="col-lg-6 col-form-label fw-semibold fs-6">Rider <br /> Name</label>
                                                        <input type="text" id="fname" name="win" class="form-control form-control-lg form-control-solid" placeholder="Enter Rider Name" value="" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <label class="col-lg-6 col-form-label required fw-semibold fs-6">Dispatch <br /> Time</label>
                                                        <input class="form-control form-control-solid flatpickr-input active" placeholder="Select Time" id="kt_datepicker_10" type="text" readonly="readonly" />
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                            {/*<!--end::Col-->*/}
                        </div>
                        {/*<!--end::Input group-->*/}

                    </div>
                    {/*<!--end::Card body-->*/}
                    {/*<!--begin::Actions-->*/}
                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        {/*<!--<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button> -->*/}
                        <button type="submit" class="btn btn-primary" id="send" disabled>Submit</button>
                    </div>
                    {/*<!--end::Actions-->*/}
                    <input type="hidden" /><div></div></form>
                {/*<!--end::Form-->*/}
            </div>
            {/*<!--end::Content-->*/}
        </div>
    );
};

export default AddCFCForm;