import React from 'react';

const BranchSelect = ({onSelect}) => {
    return (
        <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">

            <div class="text-center mb-11">

                <h1 class="text-dark fw-bolder mb-3">Branch Select</h1>

            </div>



            <div class="fv-row mb-8">

                <select name="status" aria-label="Select a Currency" data-placeholder="Select a currency.." class="form-select form-select-solid form-select-lg " data-select2-id="select2-data-121-2l6u" tabindex="-1" aria-hidden="true">
                    <option value="1" selected="selected">Select Branch</option>
                    <option value="2" selected>SMCHS</option>
                    <option value="2">II Chundrigar Branch</option>
                    <option value="3">Gulshan e Iqbal </option>
                    <option value="3">Defence </option>
                </select>

            </div>

            <br />
            <div class="d-grid mb-10">
                <div class="btn btn-primary me-2 mb-2" onClick={()=>{
                    onSelect()
                }}>Proceed</div>
            </div>

        </form>
    );
};

export default BranchSelect;