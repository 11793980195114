import { createSlice } from '@reduxjs/toolkit';
import { STRINGS } from '../../utils/Enums';
import { API } from '../../utils/services';


export const uploadFileRequest = (formData, selectedId) => (dispatch) => {
    API.post('/api/ProcessFileUpload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
        // validateStatus: (err) => {
        //     console.log("globalSlice", err)
        //     if (err === 400) {
        //         dispatch(uploadFileSuccess({
        //             data: {
        //                 data: {
        //                     isError: true,
        //                     message: 'File format is invalid for file Bpc Acquirer File'
        //                 }
        //             }, selectedId
        //         }));
        //     }
        //     if(err === 201){
        //         return '';
        //     }
        // }
    }).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.CREATE) {
            dispatch(uploadFileSuccess({ data: res.data, selectedId }));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // console.log("globalSlice", JSON.parse(JSON.stringify(ex)));
        let error = JSON.parse(JSON.stringify(ex));
        alert(error.message);
        if (error.status === 400) {
            dispatch(uploadFileSuccess({
                data: {
                    data: {
                        isError: true,
                        message: error.message
                    }
                }, selectedId
            }));
        }
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const startProcess = (data) => (dispatch) => {
    dispatch(requestPending(true));
    API.post(`/api/ProcessRun/Start`, data).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessRunStart(res.data));
            alert(res.data.message)
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        alert("Something went wrong");
        dispatch(requestPending(false));
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessById = (id) => (dispatch) => {
    API.get(`/api/ProcessFile/${id}`,).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessFilesList(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        alert("Something went wrong")
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessDetailById = (id) => (dispatch) => {
    API.get(`/api/ProcessRun/${id}`,).then((res) => {
        console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessDetails(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        console.log("globalSlice", ex)
        alert("Something went wrong")
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessRunsById = (id) => (dispatch) => {
    API.get(`/api/ProcessRuns/${id}`,).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessRunsList(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        alert("Something went wrong")
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const confirmation = (payload) => async (dispatch) => {
    dispatch(confirmDialog(payload));
};
export const openSnackBar = (payload) => async (dispatch) => {
    dispatch(setSnackBar(payload));
};
export const resetUploadResponse = (payload) => async (dispatch) => {
    dispatch(resetUploadFileResponse(payload));
};
export const resetRunProcessStart = (payload) => async (dispatch) => {
    dispatch(resetProcessRunStart(payload));
};
export const resetPFilesList = (payload) => async (dispatch) => {
    dispatch(resetProcessFilesList(payload));
};

const initialState = {
    isError: false,
    message: '',
    variant: '',
    selectedId: '',
    isPending: false,
    startProcessSuccess: false,
    // ---confirmation---
    confirmationText: '',
    openConfirmationDialog: false,
    alertIcon: '',
    alertBtnText: '',
    showCancelButton: false,
    // 
    fileUploadFailed: false,
    fileUploadSuccess: null,
    processFileIds: [],
    processFilesLists: [],
    processRunsList: [],
    processDetails: {},
    snackProps: {
        open: false,
        duration: 4000,
        message: '',
        action: false,
        actionLbl: 'Submit',
    },
};

const globalSlice = createSlice({
    name: 'globalSlice',
    initialState,
    reducers: {
        confirmDialog: (state, { payload }) => {
            state.confirmationText = payload.message;
            state.openConfirmationDialog = payload.open;
            state.alertIcon = payload.alertIcon;
            state.showCancelButton = payload.showCancelButton;
            state.alertBtnText = payload.alertBtnText;
        },
        ErrorAlert: (state, { payload }) => {
            state.isError = true;
        },
        requestPending: (state, { payload }) => {
            state.isPending = payload;
        },
        selectStatus: (state, { payload }) => {
            state.selectedBox = payload;
        },
        setDeviceIp: (state, { payload }) => {
            state.deviceIPAddress = payload.data.ip;
        },
        setProcessDetails: (state, { payload }) => {
            state.processDetails = payload.data;
        },
        uploadFileSuccess: (state, { payload }) => {
            state.fileUploadSuccess = true;
            state.fileData = payload.data;
            state.selectedId = payload.selectedId;
            state.processFileIds = [...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId), {
                process: payload.data.data,
                selected_id: payload.selectedId
            }];
        },
        setProcessFilesList: (state, { payload }) => {
            state.processFilesLists = payload.data;
        },
        resetProcessFilesList: (state, { payload }) => {
            state.processFilesLists = payload;
            state.processFileIds = payload;
        },
        setProcessRunsList: (state, { payload }) => {
            state.processRunsList = payload.data;
        },
        setProcessRunStart: (state, { payload }) => {
            state.isPending = false;
            state.startProcessSuccess = true;
        },
        resetProcessRunStart: (state, { payload }) => {
            state.startProcessSuccess = false;
        },
        uploadBulkFilesSuccess: (state) => {
            state.fileBulkUploadSuccess = true;
        },
        resetUploadFileResponse: (state, { payload }) => {
            state.fileUploadFailed = payload.failed;
            state.fileUploadSuccess = null;
            state.PO_ID = null;
        },
        resetUploadBulkFilesResponse: (state) => {
            state.fileBulkUploadSuccess = false;
        },
        setGeneratedOtp: (state, { payload }) => {
            state.otp_generated = payload === 0
        },
        setVerifiedOtp: (state, { payload }) => {
            state.otp_verified = payload === 0
        },
        resetOtp: (state) => {
            state.otp_verified = null;
            state.otp_generated = null;
        },
        setSnackBar: (state, { payload }) => {
            state.snackProps = {
                open: payload.open,
                message: payload.message,
                duration: payload.duration === undefined ? 4000 : state.snackProps.duration,
                action: payload.action !== undefined ? payload.action : state.snackProps.action,
                actionLbl: payload.actionLbl !== undefined ? payload.actionLbl : state.snackProps.actionLbl,
            };
        },
    },
});

export const {
    requestPending,
    selectStatus,
    confirmDialog,
    setSnackBar,
    uploadFileSuccess,
    resetUploadFileResponse,
    uploadBulkFilesSuccess,
    resetUploadBulkFilesResponse,
    setVerifiedOtp,
    setProcessFilesList,
    resetProcessFilesList,
    setProcessRunsList,
    setProcessRunStart,
    resetProcessRunStart,
    setProcessDetails,
    resetOtp,
} = globalSlice.actions;

export default globalSlice.reducer;

